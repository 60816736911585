import './styles/clients.css'
import neng from './images/neng.png'
import dorcas from './images/logo.png'
import nsk from './images/nsk.png'
import sunkist from './images/sunkist.png'
import inmajo from './images/inmajo.png'
import ushindi from './images/ushindi.png'
export default function Services(){
    return(
        <div className="clients">
            
            <div className="clientHead">
                <div className="sectionTitle">
                    Trusted by Industry Leaders
                </div>
                <div className="headDetails">
                    We have garnered the trust of industry leaders through our commitment to delivering innovative and reliable software solutions that drive business success.
                </div>
            </div>
            <div className="clientsList">
                <div className="client">
                    <div>
                        <img src={neng}/>
                    </div>
                    <div>Neng lang et safaries</div>
                </div>
                <div className="client">
                    <div>
                        <img src={dorcas}/>
                    </div>
                    <div>Dorcas</div>
                </div>
                <div className="client">
                    <div>
                        <img src={nsk}/>
                    </div>
                    <div>nsk hospital</div>
                    
                </div>
                <div className="client">
                    <div>
                        <img src={ushindi}/>
                    </div>
                    <div>ushindi micro credit</div>
                    
                </div>
                <div className="client">
                    <div>
                        <img src={inmajo}/>
                    </div>
                    <div>inmajo micro finance</div>
                </div>
                
                <div className="client nameonly freetown">
                    free town restaurant
                </div>
                <div className="client">
                    <div>
                        <img src={sunkist}/>
                    </div>
                    <div>sunkist</div>
                </div>
            </div>
           
        </div>
    )
}