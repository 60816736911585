import './styles/services.css'

import Globe from './images/planet-earth.png'
import Application from './images/categories.png'
import Model from './images/neural-network.png'
import Object from './images/cube.png'
import Animation from './images/animation.png'
import Shapes from './images/shapes(1).png'
export default function Services(){
    return(
        <div className="services_main">
            <div id="servicesTitleBar">
                <div className="servicesTitle">Our services</div>
                <div id="servicesTitleBarContent">
                    Discover Our Range of Tailored Software Solutions, Crafted to Empower Your Business and Drive Success in the Digital Era.
                </div>
            </div>
            <div className="services_list">

                <div className="serviceContainer">
                    <div className="serviceIllustrationContainer">
                        <img src={Globe} alt="service illustration"/>
                    </div>
                    <div className="serviceTitle">Website Development</div>
                    <div className="serviceMain">
                        Elevate your online presence with beautifully crafted and highly functional websites that leave a lasting impression on your visitors.
                    </div>
                </div>


                <div className="serviceContainer">
                    <div className="serviceIllustrationContainer">
                        <img src={Application} alt="service illustration"/>
                    </div>
                    <div className="serviceTitle">Mobile App Development</div>
                    <div className="serviceMain">
                        Tap into the rapidly growing mobile market with intuitive and engaging mobile applications for iOS and Android platforms.
                    </div>
                </div>



                <div className="serviceContainer">
                    <div className="serviceIllustrationContainer">
                        <img src={Model} alt="service illustration"/>
                    </div>
                    <div className="serviceTitle">Machine Learning</div>
                    <div className="serviceMain">
                        Harness the power of data with advanced machine learning models that drive insights, predictions, and automation for your business processes.
                    </div>
                </div>


                <div className="serviceContainer">
                    <div className="serviceIllustrationContainer">
                        <img src={Object} alt="service illustration"/>
                    </div>
                    <div className="serviceTitle">3D Modeling</div>
                    <div className="serviceMain">
                        From product prototypes to architectural visualizations, our skilled team brings your concepts to life with precision and creativity through 3D modeling.
                    </div>
                </div>
        
            
                <div className="serviceContainer">
                    <div className="serviceIllustrationContainer">
                        <img src={Animation} alt="service illustration"/>
                    </div>
                    <div className="serviceTitle">3D Animation</div>
                    <div className="serviceMain">
                        Captivate your audience with immersive 3D animations that communicate your message effectively and leave a lasting impact.
                    </div>
                </div>

                <div className="serviceContainer">
                    <div className="serviceIllustrationContainer">
                        <img src={Shapes} alt="service illustration"/>
                    </div>
                    <div className="serviceTitle">Computer Software Development</div>
                    <div className="serviceMain">
                    Whether you need desktop applications, enterprise software, or specialized tools, we develop reliable and scalable solutions for all platforms.
                    </div>
                </div>

                <div className="serviceContainer">
                   
                </div>

                <div className="serviceContainer">
                  
                </div>
            </div>
 

            

        

            

           
        </div>
    )
}