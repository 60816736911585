import './styles/innovation.css'

export default function Services(){
    return(
        <div className="innovation">
            
            <div className="innovationSection sectionTitle">
                Innovative Technology for Business Empowerment
            </div>
            <div className="innovationSection">
                We are committed to leveraging the latest technology to empower businesses and drive growth. 
                Our mission is to provide top-notch solutions that meet the unique needs of our clients, helping them achieve their objectives and stay ahead in their industry.
            </div>
           
        </div>
    )
}