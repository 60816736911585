import './styles/ml.css'
import ml from './images/ml.svg'

import design from './images/ml 1.svg'
import Implementation from './images/ml 2.svg'
export default function whyus(){
    return(
        <div className="centered_container point_main">
            
            <div className="whyus_illustration">
                <img src={ml}/>
            </div>

            <div id="point">
              
                <div id="whyusHead">Machine learning</div>
              
                <div>      
                    <span>
                        We believe intransparent communication and close collaboration with our clients every step of the way, ensuring that your input shapes the final product.
                    </span>
                </div>

                <div className="PointKeys">
                    <div className="PointKey">
                        <div>
                            <img src={design}/>
                        </div>
                        <div>Design</div>
                    </div>
                    <div className="PointKey">
                        <div>
                            <img src={Implementation}/>
                        </div>
                        <div>Implementation</div>
                    </div>
                </div>
            </div>

           

        </div>
    )
}