import React from 'react'
import { Drawer, ListItem, ListItemText } from '@mui/material'
import logo from '../resources/images/logo.svg'
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu';

import '../../resources/styles/global.css'
import './styles/nav.css'

export default function Nav(props){
    const [drawerState, setDrawerState] = React.useState(false)
    const toggleDrawer = () =>{
        console.log('called')
        setDrawerState(prev => !prev)
    }

    return (
        <div>
            <div className="Nav">
                <div className="flexRow">
                    <div>
                        <img className="logo" src={logo} alt="logo" aria-label="sublime logo"/>
                    </div>
                    <div className="logoname">
                        <div>
                            <Link className="nLink" to="/">sublime technologies</Link>
                        </div>
                        <div>crafting creative solutions</div>
                    </div>
                </div>
                <div className="flexRow">
                    
                  
                </div>

                <div className="flexRow">
                    
                    <div className="NavLink">
                        <Link className="nLink" to="/">Services</Link>
                    </div>
                    <div className="NavLink">
                        <Link className="nLink" to="/">Products</Link>
                    </div>
                    <div className="drawerController">
                        <IconButton aria-label="delete" onClick={toggleDrawer}>
                            <MenuIcon />
                        </IconButton>
                    </div>
                </div>
               
            </div>
            <div>
                <Drawer
                    anchor='right'
                    open={drawerState}
                    onClose={toggleDrawer}
                >
                    <div style={{paddingTop:'20px'}}>
                        <div className="homeDrawer" onClick={toggleDrawer}>
                            <div>
                                <img className="logo" src={logo} alt="logo" aria-label="sublime logo"/>
                            </div>
                            <div className="logoname">
                                <Link className="nLink" to="/">sublime technologies</Link>
                                
                            </div>
                        </div>
                        <div>
                            <Link className="nLink" to='/services'>
                                <ListItem onClick={toggleDrawer} button>
                                    <ListItemText  className="drawerItem">
                                        Services
                                    </ListItemText>
                                </ListItem>
                            </Link>
                            <Link className="nLink" to='/projects'>
                                <ListItem onClick={toggleDrawer} button >
                                    <ListItemText  className="drawerItem">
                                        Projects
                                    </ListItemText>
                                </ListItem>
                            </Link>
                        </div>

                    
                    </div>
                </Drawer>
            </div>

        </div>  
    )
}