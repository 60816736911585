import './styles/fin.css'
import Final from './images/10168.png'
export default function Fin(){
    return(
        <div className="FinMain">
            <div className="finTitle">
                
         
                Experience the transformative power of technology in revolutionizing your business operations. Our solutions are geared towards maximizing productivity and driving success.
              
            </div>

            <div className='finIllustration'>
                <img src={Final}/>
            </div>
            
        </div>
    )
}