import { useEffect, useState } from 'react'
import './styles/process.css'
import Stage from './stage'
import RequirmentsIcon from './images/requirement.png'
import AnalysisIcon from './images/analysis.png'
import DesignIcon from './images/curve.png'
import TestingIcon from './images/unit-testing.png'
import DevelopmentIcon from './images/web-programming.png'
import DeploymentIcon from './images/deployment.png'
import MaintenenceIcon from './images/wrench.png'
import IterationIcon from './images/agility.png'
import DocumentationIcon from './images/document(1).png'
import ProjectClosure from './images/closure.png'



import Deal from './images/illustrations/Business deal-rafiki.svg'
import Solution from './images/illustrations/Business solution-cuate-1.svg'
import Code from './images/illustrations/Code typing-bro.svg'
import Design from './images/illustrations/Design team-amico.svg'
import Maintenence from './images/illustrations/Pipeline maintenance-amico.svg'
import Bugs from './images/illustrations/QA engineers-amico.svg'
import Research from './images/illustrations/Research paper-bro.svg'
import Deploy from './images/illustrations/Upload-bro.svg'
import { Brightness1 } from '@mui/icons-material'


export default function(){

    const [ start, setStart ] = useState(false)
    const [ active, setActive ] = useState('')
    const [ image, setImage ] = useState('')

    const bridge = {
        'Requirements Gathering' : Research,
        'Analysis and Planning' : Solution,
        'Design' : Code,
        'Development' : Design ,
        'Testing' : Maintenence,
        'Deployment' : Bugs,
        'Project Closure' : Deal,
        'Maintenance and Support' : Deploy,
    }

    function selectStage(stage){
        setActive(stage)
        setImage(bridge[stage])
        console.log(bridge[stage])
    }
    useEffect(() => {
        if(!start){
            selectStage('Requirements Gathering')
            setStart(true)
        }
        
    })

   


    return(
        <div className="centered_container processMain">
            <div id="processTitle">
                <div id="processIntro">
                    <div>Make the complex,</div>
                    <div>simple</div>
                </div>
                <div>Delivering a great experience, no matter how complex your product, has never been simpler.</div>
            </div>
            <div id="processBottomMain">
                <div id="processIllustration">
                    <img src={image} alt="process illustration"/>
                </div>
                <div>
                    <Stage 
                        active={active}
                        select={selectStage}
                        title="Requirements Gathering"
                        icon={RequirmentsIcon}
                        description="Gather client needs and project goals."
                    />
    
                    <Stage 
                        active={active}
                        select={selectStage}
                        title="Analysis and Planning"
                        icon={AnalysisIcon}
                        description="Plan project scope, timeline, and resources effectively."
                    />
    
                    <Stage 
                        active={active}
                        select={selectStage}
                        title="Design"
                        icon={DesignIcon}
                        description="Craft intuitive UI/UX design and prototypes."
                    />
                    
                    <Stage 
                        active={active}
                        select={selectStage}
                        title="Development"
                        icon={DevelopmentIcon}
                        description="Code and integrate features with precision."
                    />
    
                    <Stage 
                        active={active}
                        select={selectStage}
                        title="Testing"
                        icon={TestingIcon}
                        description="Ensure functionality, performance, and security through rigorous testing."
                    />
    
    
                    <Stage 
                        active={active}
                        select={selectStage}
                        title="Deployment"
                        icon={DeploymentIcon}
                        description="Implement product into production environment seamlessly."
                    />
    
    
                    <Stage 
                        active={active}
                        select={selectStage}
                        title="Project Closure"
                        icon={ProjectClosure}
                        description="Review project, finalize paperwork, and conclude the project lifecycle."
                    />  
    
    
                    <Stage 
                        active={active}
                        select={selectStage}
                        title="Maintenance and Support"
                        icon={MaintenenceIcon}
                        description="Offer continual support and updates for longevity."
                    />
                </div>
            </div>
            

        </div>
    )
}


/*
    <Stage 
        active={active}
        select={selectStage}
        title="Documentation"
        icon={DocumentationIcon}
        description="Create comprehensive technical and user documentation."
    />

    <Stage 
        active={active}
        select={selectStage}
        title="Feedback and Iteration"
        icon={IterationIcon}
        description="Gather feedback, iterate, and refine as necessary."
    />


*/