import './styles/whyus.css'
import Whyus from './images/Time management.svg'
export default function whyus(){
    return(
        <div className="centered_container whyus_main">
            
            <div className="whyus_illustration">
                <img src={Whyus}/>
            </div>
            <div id="whyUspoints">
                <div id="why_us_start">start</div>
                <div id="whyusHead">Why choose us</div>
                <div className='whyuspoint'>

                    <span className="heading">Expertise</span>
                    <span>
                        Our team comprises seasoned professionals with a wealth of experience in software development across diverse industries.
                    </span>
                </div>

                <div className='whyuspoint'>
             
                    <span className="heading">Innovation</span>
                    <span>
                        We stay ahead of the curve by embracing the latest technologies and methodologies to deliver solutions that exceed expectations.
                    </span>
                </div>
                
                <div className='whyuspoint'>
                    <span className="heading">Customization</span>
                    <span>
                        Every project is unique, and we tailor our approach to meet your specific goals, ensuring a solution that aligns perfectly with your vision.
                    </span>
                </div>
                
                <div className='whyuspoint'>
                    <span className="heading">Collaboration</span>
                    
                    <span>
                        We believe intransparent communication and close collaboration with our clients every step of the way, ensuring that your input shapes the final product.
                    </span>
                </div>
            </div>
        </div>
    )
}