import React from 'react'
import logo from '../resources/images/logo.svg'

import './styles/footer.css'
import '../../resources/styles/global.css'


export default function Footer(props){
    return (
        <div className="footer">
           

            <div>
                <div className="footerHead">
                    <div className="flexRow">
                        <div>
                            <img className="logo" src={logo} alt="logo" aria-label="sublime logo"/>
                        </div>
                        <div className="logoname">
                            <div>
                                sublime technologies
                            </div>
                            <div>crafting creative solutions</div>
                        </div>
                    </div>
                </div>
                <div className="footerSectionContainer">
                    <div className="footerSection">
                        <div className="footerSectionHeading">Contact us</div>
                        <div className="footerSectionDetails">
                  
                            <div>
                                Block 3 Plot J Pangani Street, Arusha, Tanzania
                            </div>
                            <div>
                                <div>General Inquiries: </div>
                                <div>info@sublimetanzania.com</div>
                            </div>
                            <div>
                                <div>Sales: </div>
                                <div>sales@sublimetanzania.com</div>
                            </div>
                            <div>
                                <div>Customer Support: </div>
                                <div>support@sublimetanzania.com</div>
                            </div>
                
                        </div>
                    </div>

                    <div className="footerSection">
                        <div className="footerSectionHeading">Quick links</div>
                        <div className="footerSectionDetails">
                  
                           <div>
                                <div className="footerSectionLink">
                                    Terms and conditions
                                </div>
                                <div className="footerSectionLink">
                                    Privacy policy
                                </div>
                           </div>
                            
                
                        </div>
                    </div>


                    <div className="footerSection">
                        <div className="footerSectionHeading">Connect with us</div>
                        <div className="footerSectionDetails">
                  
                           <div>
                                <div className="padv10">
                                    Stay updated with the latest news and developments.
                                </div>
                                <div className="">
                                    <div className="footerEmail">
                                        <div>Email *</div>
                                        <div>
                                            <input className="footerEmailInput" type="email" placeholder="Enter your email address" />
                                        </div>
                                    </div>
                                    <div className="flex_row">
                                        <div>
                                            <input type='checkbox'/>
                                        </div>
                                        <div className="padh10">
                                            yes, subscribe to your newsletter 
                                        </div>
                                    </div>
                                    <div>
                                        <div className='btn'>
                                            subscribe
                                        </div>
                                    </div>
                                </div>
                           </div>
                            
                
                        </div>
                    </div>


                    <div className="footerSection">
                    
                        <div className="footerSectionDetails">
                  
                           <div>
                                <div className="footerSectionSocials">
                                    Facebook
                                </div>
                                <div className="footerSectionSocials">
                                    Youtube
                                </div>
                                <div className="footerSectionSocials">
                                    Instagram
                                </div>
                           </div>
                            
                
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="footer_bottom">
                © {new Date().getFullYear()} Sublime Technologies. All Rights Reserved
              
            </div>

        </div>
    )
}

/*
    <div id="footerSectionContainer">

                <div className="footer_section">
                    
                    <div>JMHQ+6G Arusha</div>
            
                    <div className="icon_details">
                     
                        <div>sublimetechnologiestanzania@gmail.com</div>
                    </div>
                    <div className=" icon_details number">
                       
                        <div>+255 623 082 331</div>
                    </div>
                </div>

            </div>
*/