import './styles/splash.css'
import { TypewriterEffect } from "../../effects/typewriter"
import video from './video/sea.mp4'

import efficiency from './images/splash 1.svg'
import scalability from './images/splash 2.svg'
import collaboration from './images/splash 3.svg'

export default function Splash(){

    return(
        <div className="splash">
          
          <div id="foreGround">
            <div className="splashInfoSection">
              <div className="splashSectionMain">
                <div className="splashSectionHeading">
                  Empowering your business with innovative solutions
                </div>
                <div className="splash_sub">We're passionate about making ideas real with innovative software. Whether it's a cutting-edge website, powerful web app, sleek mobile app, or utilizing machine learning, 3D modeling, animation, or custom software, we've got the expertise to bring your vision to life</div>
                <div className="splash_btn">
                    Get started
                </div>
              </div>
              <div className="getStartedFeatures">
                <div className="getStartedFeature">
                  <div>
                    <img src={efficiency}/>
                  </div>
                  <div>Efficiency & Excellence</div>
                </div>

                <div className="getStartedFeature">
                  <div>
                    <img src={scalability}/>
                  </div>
                  <div>Scalability & Adaptability</div>
                </div>

                <div className="getStartedFeature">
                  <div>
                    <img src={collaboration}/>
                  </div>
                  <div>Fostering Collaboration</div>
                </div>

              </div>
            </div>
          </div>

          <div id="background">
            <video className="splashVideo" src={video} autoPlay loop></video>
          </div>
        </div>
    )
}