
import pos from './images/pos.svg'
import customization from './images/pos 1.svg'
import Installation from './images/pos 2.svg'
import devices from './images/pos 3.svg'
export default function whyus(){
    return(
        <div className="centered_container point_main">
            <div id="point">
              
                <div id="whyusHead">Point of sale system</div>
              
                <div>      
                    <span>
                        We believe intransparent communication and close collaboration with our clients every step of the way, ensuring that your input shapes the final product.
                    </span>
                </div>


                <div className="PointKeys">
                    <div className="PointKey">
                        <div>
                            <img src={customization}/>
                        </div>
                        <div>Customization</div>
                    </div>
                    <div className="PointKey">
                        <div>
                            <img src={Installation}/>
                        </div>
                        <div>Installation</div>
                    </div>
                    <div className="PointKey">
                        <div>
                            <img src={devices}/>
                        </div>
                        <div>devices</div>
                    </div>
                </div>
            </div>

            <div className="whyus_illustration">
                <img src={pos}/>
            </div>
        </div>
    )
}