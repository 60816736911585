import '../resources/styles/global.css'
import '../resources/styles/index.css'

import Nav from '../elements/Nav/Nav'
import Footer from '../elements/Footer/footer'
import Splash from '../elements/Splash/splash'

import Whyus from '../elements/whyUs/why_us'
import Services from '../elements/Services/services'

import Process from '../elements/Process/process'

import Final from '../elements/Final/fin'

import Ml from '../elements/ML/ml'
import Pos from '../elements/Pos/pos'

import Innovation from '../elements/Innovation/innovation'
import Clients from '../elements/Clients/clients'
export default function Home(){
    return(
        <div className="">
            <Nav/>
            <div>
                <Splash/>
                <Innovation/>
                <Services />
                <Process/>
                <Ml/>
                <Pos/>
               
                <Clients/>
                <Whyus />
                <Final/>
           
           
            </div>
            <div>
                
            </div>
            <Footer/>
        </div>
    )
}